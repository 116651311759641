- section: Skills
  items:

    - id: bane-signet
      text: Bane Signet
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [180, buff]
      gw2id: 9093
      temporaryBuff: activeOutOfCombat

    - id: bane-signet-2
      text: Bane Signet
      subText: traited
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [216, buff]
      gw2id: 9093
      temporaryBuff: activeOutOfCombat

    - id: signet-of-judgement
      text: Signet of Judgement
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      gw2id: 9150

    - id: signet-of-judgement-2
      text: Signet of Judgement
      subText: traited
      modifiers:
        damage:
          Damage Reduction: [12%, unknown]
      gw2id: 9150

    - id: signet-of-mercy
      text: Signet of Mercy
      modifiers:
        attributes:
          Concentration: [120, buff]
      gw2id: 9163
      temporaryBuff: false

    - id: signet-of-mercy-2
      text: Signet of Mercy
      subText: traited
      modifiers:
        attributes:
          Concentration: [150, buff]
      gw2id: 9163
      temporaryBuff: false

    - id: signet-of-wrath
      text: Signet of Wrath
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Condition Damage: [180, buff]
      gw2id: 9151
      temporaryBuff: activeOutOfCombat

    - id: signet-of-wrath-2
      text: Signet of Wrath
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      subText: traited
      modifiers:
        attributes:
          Condition Damage: [216, buff]
      gw2id: 9151
      temporaryBuff: activeOutOfCombat

    # 0.7 coefficient, 690.5(?) strength, 18 proc/120s traited
    # burning: 4s
    #
    # f1 passives from procs are 2.4s because of amplified wrath; every 3 hits if virtues
    # passive burning : 1/3 * 2.4 = 0.8
    # - id: mantra-of-flame
    #   text: Mantra of Flame
    #   subText: (with passive + permeating + amplified)
    #   amountData:
    #     label: '/10s'
    #     default: 1.5
    #     quantityEntered: 10
    #   modifiers:
    #     attributes:
    #       Power Coefficient: 483.3
    #       Burning Coefficient: 14.4
    #   wvwModifiers:
    #     attributes:
    #       Power Coefficient: 483.3
    #       Burning Coefficient: 4.8
    #   gw2id: 46148
    #   temporaryBuff: true

    # 0.7 coefficient, 690.5(?) strength, 18 proc/120s traited
    # burning: 4s
    # - id: mantra-of-flame-base
    #   text: Mantra of Flame
    #   subText: (no passive)
    #   amountData:
    #     label: '/10s'
    #     default: 1.5
    #     quantityEntered: 10
    #   modifiers:
    #     attributes:
    #       Power Coefficient: 483.3
    #       Burning Coefficient: 12
    #   wvwModifiers:
    #     attributes:
    #       Power Coefficient: 483.3
    #       Burning Coefficient: 4
    #   gw2id: 46148
    #   temporaryBuff: true

    - id: binding-blade
      text: Binding Blade
      subText: damage over time tick (approx.)
      amountData:
        label: '/10s'
        default: 0
        defaultInput: ???
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 160
          Siphon Coefficient: 0.3
      wvwModifiers:
        attributes:
          Siphon Base Coefficient: 160
          Siphon Coefficient: 0.1
      gw2id: 9147
      temporaryBuff: true

- section: Zeal
  id: 42
  items:

    - id: fiery-wrath
      text: Fiery Wrath
      modifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      gw2id: 634
      defaultEnabled: true

    - id: symbolic-exposure
      text: Symbolic Exposure
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, mult]
      gw2id: 646
      defaultEnabled: true

    - id: zealous-blade-base
      text: Zealous Blade
      subText: base
      modifiers:
        attributes:
          Power: [120, buff] #confirmed by Cat
      gw2id: 653
      defaultEnabled: true
      temporaryBuff: false

    - id: zealous-blade-gs
      text: Zealous Blade
      subText: with greatsword
      amountData:
        label: '% uptime'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [120, converted] #confirmed by Cat
      gw2id: 653
      defaultEnabled: true
      temporaryBuff: true # assume the user is on a dual-wield set

    - id: kindled-zeal
      text: Kindled Zeal
      modifiers:
        conversion:
          Condition Damage: {Power: 10%}
      gw2id: 1556
      defaultEnabled: true
      temporaryBuff: false

    - id: symbolic-avenger
      text: Symbolic Avenger
      subText: 5x
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      gw2id: 2017
      defaultEnabled: true

- section: Radiance
  id: 16
  items:

    - id: right-hand-strength
      text: Right-Hand Strength
      subText: base (+80 prec)
      modifiers:
        attributes:
          Precision: [80, buff] # confirmed from bench hero panel
      gw2id: 566
      defaultEnabled: true
      temporaryBuff: false

    - id: right-hand-strength-1h
      text: Right-Hand Strength
      subText: with 1h weapon
      amountData:
        label: '% uptime'
        default: 50
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [80, converted] # found by Cat
      gw2id: 566
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # assume the user is on a dual-wield set

    - id: radiant-fire
      text: Radiant Fire
      modifiers:
        attributes:
          Burning Duration: 20%
      wvwModifiers:
        attributes:
          Burning Duration: 7%
      gw2id: 567
      defaultEnabled: true
      temporaryBuff: false

    - id: retribution
      text: Retribution
      amountData:
        label: '% resolution'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      gw2id: 565
      defaultEnabled: true

    - id: radiant-power
      text: Radiant Power
      subText: 100% burning enemy
      minor: true
      modifiers:
        attributes:
          Critical Chance: 10%
          Ferocity: [150, buff]
      gw2id: 568
      defaultEnabled: true
      temporaryBuff: true

    - id: amplified-wrath
      text: Amplified Wrath
      modifiers:
        damage:
          Outgoing Burning Damage: [10%, mult] # unconfirmed
      wvwModifiers:
        damage:
          Outgoing Burning Damage: [15%, mult] # unconfirmed
      gw2id: 1686
      defaultEnabled: true

    - id: righteous-instincts
      text: Righteous Instincts
      subText: 100% resolution
      modifiers:
        attributes:
          Critical Chance: 25%
      gw2id: 1683
      defaultEnabled: true
      temporaryBuff: true

- section: Valor
  id: 13
  items:

    - id: stalwart-defender
      text: Stalwart Defender
      subText: with shield
      modifiers:
        attributes:
          Toughness: [240, buff]
      gw2id: 580
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # assume the user is on a dual-wield set

- section: Honor
  id: 49
  items:

    - id: invigorated-bulwark
      text: Invigorated Bulwark
      amountData:
        label: 'stacks'
        default: 5
        quantityEntered: 5
      modifiers:
        attributes:
          Outgoing Healing: 25%
      wvwModifiers:
        attributes:
          Outgoing Healing: 10%
      gw2id: 1899
      defaultEnabled: true
      temporaryBuff: true

    - id: honorable-staff
      text: Honorable Staff
      subText: base
      modifiers:
        attributes:
          Concentration: [120, buff] # tested by Stephan
      wvwModifiers:
        attributes:
          Concentration: [60, buff] # tested by Stephan
      gw2id: 557
      defaultEnabled: true
      temporaryBuff: false

    - id: force-of-will
      text: Force of Will
      modifiers:
        attributes:
          Vitality: [300, converted] # tested by Stephan
        conversion:
          Outgoing Healing: {Vitality: 0.01%}
      wvwModifiers:
        attributes:
          Vitality: [300, converted] # tested by Stephan
        conversion:
          Outgoing Healing: {Vitality: 0.005%}
      gw2id: 1682
      defaultEnabled: true
      temporaryBuff: false

- section: Virtues
  id: 46
  items:

    - id: inspired-virtue
      text: Inspired Virtue
      amountData:
        label: 'boons'
        default: 10
        quantityEntered: 1
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [1%, mult]
      gw2id: 621
      defaultEnabled: true

    - id: unscathed-contender-health
      text: Unscathed Contender
      subText: with scholar
      amountData:
        label: '% scholar'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      gw2id: 624
      defaultEnabled: true

    - id: unscathed-contender
      text: Unscathed Contender
      subText: with aegis
      amountData:
        label: '% aegis'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [7%, add]
      gw2id: 624
      defaultEnabled: true

    - id: virtue-of-resolution
      text: Virtue of Resolution
      minor: true
      modifiers:
        attributes:
          Resolution Duration: 25%
      gw2id: 604
      defaultEnabled: true
      temporaryBuff: false

    - id: inspiring-virtue
      text: Inspiring Virtue
      amountData:
        label: '% uptime'
        default: 70
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      gw2id: 603
      defaultEnabled: true

    - id: power-of-the-virtuous
      text: Power of the Virtuous
      subText: conversion only
      minor: true
      modifiers:
        conversion:
          Condition Damage: {Vitality: 7%}
      wvwModifiers:
        conversion:
          Condition Damage: {Vitality: 13%}
      gw2id: 620
      defaultEnabled: true
      temporaryBuff: false

- section: Dragonhunter
  id: 27
  items:
    - id: defenders-dogma
      text: Defender's Dogma
      minor: true
      modifiers:
        attributes:
          Vitality: [180, converted]
      gw2id: 1896
      defaultEnabled: true
      temporaryBuff: false

    - id: zealots-aggression
      text: Zealot's Aggression
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1835
      defaultEnabled: true

    - id: pure-of-sight
      text: Pure of Sight
      subText: melee
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [5%, mult]
      gw2id: 1926
      defaultEnabled: true

    - id: pure-of-sight-ranged
      text: Pure of Sight
      subText: ranged
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, mult]
      gw2id: 1926
      defaultEnabled: false

    - id: heavy-light-defiant
      text: Heavy Light
      subText: against defiant foes, base
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, unknown]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
      gw2id: 1963
      defaultEnabled: true

    - id: heavy-light-defiant-disabled
      text: Heavy Light
      subText: against defiant foes, bonus
      amountData:
        label: '% disabled'
        default: 0
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, unknown] # sure hope this is additive...?
      gw2id: 1963
      defaultEnabled: true

    - id: heavy-light-disabled
      text: Heavy Light
      subText: against non-defiant foes, bonus
      amountData:
        label: '% disabled'
        default: 0
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, unknown]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [15%, unknown]
      gw2id: 1963
      defaultEnabled: false

    - id: big-game-hunter
      text: Big Game Hunter
      amountData:
        label: '% uptime'
        default: 75
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [25%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [15%, mult]
      gw2id: 1955
      defaultEnabled: true

- section: Firebrand
  id: 62
  note: Be sure to change the burning coefficient value below when adding or removing traits without checkboxes like legendary lore.
  items:

    - id: imbued-haste
      text: Imbued Haste
      minor: true
      amountData:
        label: '% quickness'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Condition Damage: [250, converted]
          Healing Power: [250, converted]
          Vitality: [250, converted] # confirmed from bench hero panel
      wvwModifiers:
        attributes:
          Condition Damage: [150, converted]
          Healing Power: [150, converted]
          Vitality: [150, converted]
      gw2id: 2148
      defaultEnabled: true
      temporaryBuff: true

- section: Willbender
  id: 65
  items:

    - id: searing-pact
      text: Searing Pact
      modifiers:
        attributes:
          Condition Damage: [120, unknown]
      gw2id: 2191
      defaultEnabled: true
      temporaryBuff: false

    - id: power-for-power
      text: Power for Power
      modifiers:
        attributes:
          Power: [120, unknown]
      gw2id: 2190
      defaultEnabled: true
      temporaryBuff: false

    - id: conceited-curate
      text: Conceited Curate
      modifiers:
        attributes:
          Vitality: [180, converted]
      gw2id: 2187
      defaultEnabled: true
      temporaryBuff: false

    - id: lethal-tempo
      text: Lethal Tempo
      minor: true
      amountData:
        label: 'stacks'
        default: 5
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [2%, add] # as per Cat
          Outgoing Condition Damage: [2%, add] # ???
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [3%, add] # as per Cat
          Outgoing Condition Damage: [3%, add] # ???
      gw2id: 2189
      defaultEnabled: true

    - id: tyrants-momentum
      text: Tyrant's Momentum
      subText: bonus
      amountData:
        label: 'stacks'
        default: 5
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [3%, add] # as per Cat
          Outgoing Condition Damage: [3%, add] # ???
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [2%, add] # as per Cat
          Outgoing Condition Damage: [2%, add] # ???
      gw2id: 2201
      defaultEnabled: true

    - id: tyrants-momentum-bugged
      text: Tyrant's Momentum
      subText: bugged 10% chill mod
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult] # as per Cat
      gw2id: 2201
      defaultEnabled: false

    - id: deathless-courage
      text: Deathless Courage
      amountData:
        label: '% uptime'
        default: 0
        defaultInput: ???
        quantityEntered: 100
      modifiers:
        damage:
          Damage Reduction: [50%, unknown]
      gw2id: 2198
      defaultEnabled: true
